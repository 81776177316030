import { createI18n } from "vue-i18n";
import Vue from 'vue';

console.log('i18n navigator.language:', navigator.language)
let browserLng = navigator.language
browserLng = browserLng.slice(0, browserLng.indexOf("-"))

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: browserLng,
    fallbackLocale: ['en', 'cs'],
    messages : { // můžem nastavit něco do začátku, kdyby nešlo načíst API
      cs: {
        catCode: 'koookoooot',
        catProductdetail: 'bauchMoneyMix'
      },
      en: {
        catCode: 'koookooootEN',
        catProductdetail: 'bauchMoneyMixEN'
      }
    },
})

async function loadAllMessages() {
  console.log('main.js i18n.global', i18n.global.messages.value)
  const response = await fetch(`https://appgate.essensworld.com/translate.php?prefix=kickoff`)
  const messages = await response.json()
  console.log('loadMessages() messages', messages)
  Object.entries(messages).forEach(([lng, value]) => {
    i18n.global.setLocaleMessage(lng, messages[lng])
  });
}

loadAllMessages()

/* function reloadMessages(locale) {
  // const response = await fetch(`/api/messages/${locale}`)
  // const messages = await response.json()
  const messages = {
    code: 'koookoooot',
    productDetail: 'bauchMoneyMix'
  }
  setTimeout((() => {
    console.log('reloadMessages() messages', messages)
    i18n.global.messages[locale] = messages
    i18n.global.setLocaleMessage(locale, i18n.global.messages[locale])
  }), 5000);
  
} */

/*
    messages:
    {
        en: {
            navbarHome: 'Home',
            navbarEvent: 'Event',
            onlineVideo: 'Online video',
            tickets: 'Tickets',
            slide: 'slideeee'
        },
        cs: {
            navbarHome: '<span style="color:red;">Domov</span>',
            navbarEvent: 'Událost',
            onlineVideo: 'Online video',
            tickets: 'Vstupenky',
            slide: 'slidujeeeem'
        }
    } 
  */


export default i18n