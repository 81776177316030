<template>
  <div id="home">
    <welcomeScreen />
    <aboutEvent />
    <essensTvPromo sectionId="essenstv-promo" />
    <performers />
    <afterMovies />
    <essensTvPromo sectionId="essenstv-promo-2" />
    <photoGallery />
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'

  import WelcomeScreen from '@/components/homepage/welcomeScreen.vue'
  import AboutEvent from '@/components/homepage/aboutEvent.vue'
  import performers from '@/components/homepage/performers.vue'
  import essensTvPromo from '@/components/homepage/essensTvPromo.vue'
  import photoGallery from '@/components/homepage/photoGallery.vue'
  import afterMovies from '@/components/homepage/afterMovies.vue'
  
  const route = ref(useRoute())
  /* onMounted(() => {}) */
</script>

<style>
.navbar.zero-bg {
    background-color: #ffffff00;
}
</style>