const menuData = [
    { name: 'webHome', link: '/' },
    { name: 'webEvent', link: '/#about-event' },
    { name: 'webRecord', link: '/#essenstv-promo' },
    { name: 'webUcinkujici', link: '/#performers' },
    { name: 'webVidea', link: '/#after-movies' },
    { name: 'webGallery', link: '/#photo-gallery' },
    /* { isNotKey: true, name: 'Test swiper', link: '/test_swiper' },
    { isNotKey: true, name: 'gallery UK', link: '/gallery/uk' }, */
]
export default menuData