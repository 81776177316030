<template>
    <section id="performers">
        <div class="container">
            <div class="secondary-heading text-center">ESSENS KICK OFF</div>
            <h1 class="text-center mb-5"> {{ $t('webUcinkujici') }} </h1>
            <div class="row">
                <template v-for="performer in performers" :key="performer">
                    <template v-if="!performer.rusOnly">
                        <Performer :performer="performer"/>
                    </template>
                    <!-- Rusák navíc jen v Ruštině -->
                    <template v-if="locale == 'ru' && performer.rusOnly">
                        <Performer :performer="performer"/>
                    </template>
                </template>
            </div>
        </div>
    </section>
</template>

<script setup>
    import Performer from '@/components/homepage/onePerformer.vue'
    import { useI18n } from 'vue-i18n';
    const { locale } = useI18n()

    const imageSrcKovar = require('@/assets/images/performers/kovar.png')
    const imageSrcRys = require('@/assets/images/performers/rys.png')
    const imageSrcRus = require('@/assets/images/performers/rus.png')

    const performers = [
        { imgSrc: imageSrcKovar, name: 'Michal Kovář', job: 'CEO ESSENS', txtKey: 'webMkText' },
        { imgSrc: imageSrcRys, name: 'Petr Ryšávka', jobKey: 'webRysHead', txtKey: 'webRysText' },
        { imgSrc: imageSrcRus, name: 'РАДИСЛАВ ГАНДАПАС', job: 'Русский мотивационный спикер', txtKey: 'webPersonRuText', rusOnly: true, headingStyle: 'font-size: 20px' },
    ]
</script>

<style>
    #performers {
        background-color: var(--grey-bg);
    }

    .performer {
        padding: 15px;
        margin-bottom: 3rem;
        text-align: center;
        transition: .5s;
    }

    .performer .job {
        color: var(--secondary);
    }

    .performer:hover {
        background-color: white;
        box-shadow: 0px 0px 10px #0000000f;
    }

    .performer img {
        margin-bottom: 1rem;
    }

    .performer .txt {
        margin-top: 1rem;
        text-align: justify;
    }

    .performer .left-side {
        min-width: 250px;
    }

    @media (min-width: 992px) {
        .performer {
            padding-right: 3rem !important;
        }
    }
</style>