<template>
    <section id="photo-gallery">
        <div class="container container-xl-fluid">
            <div class="secondary-heading text-center">ESSENS KICK OFF</div>
            <h1 class="text-center mb-5"> {{ $t('webGallery') }} </h1>
            <div class="row">
                <div v-for="gallery in galleries" :key="gallery" class="col-xl-3 col-lg-4 col-6 my-4 my-lg-5 px-lg-4">
                    <router-link :to="gallery.href" class="gallery-thumbnail d-block text-center">
                        <img :src="gallery.imgSrc" :alt="gallery.name + ' image'">
                        <img class="mt-3 mb-1" :src="'https://www.essenskickoff.com/2022/img/flag/'+gallery.flag+'.png'" alt="United Kingdom" style="width: 25px;">
                        <div class="thumbnail-h ">{{ gallery.name }}</div>
                        <!-- <button class="btn btn-primary d-table mx-auto mt-3">Zobrazit</button> -->
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const imageCz = require('@/assets/images/galleryThumbnails/gallery_cz.jpg')
    const imageEs = require('@/assets/images/galleryThumbnails/gallery_es.jpg')
    const imageGr = require('@/assets/images/galleryThumbnails/gallery_gr.jpg')
    const imageIt = require('@/assets/images/galleryThumbnails/gallery_it.jpg')
    const imageKz = require('@/assets/images/galleryThumbnails/gallery_kz.jpg')
    const imageLt = require('@/assets/images/galleryThumbnails/gallery_lt.jpg')
    const imageRu = require('@/assets/images/galleryThumbnails/gallery_ru.jpg')
    const imageUk = require('@/assets/images/galleryThumbnails/gallery_uk.jpg')

    const galleries = [
        { name: 'Czech Republic', imgSrc: imageCz, href: '/gallery/czech', flag: 'cz' }, /* ESSENS Kick OFF Tour 2023*/
        { name: 'Spain', imgSrc: imageEs, href: '/gallery/spain', flag: 'es' },
        { name: 'Greece', imgSrc: imageGr, href: '/gallery/greece', flag: 'gr' },
        { name: 'Italy', imgSrc: imageIt, href: '/gallery/italy', flag: 'it' },
        { name: 'United Kingdom', imgSrc: imageUk, href: '/gallery/uk', flag: 'uk' },
        { name: 'Kazakhstan', imgSrc: imageKz, href: '/gallery/kazakhstan', flag: 'kz' },
        { name: 'Latvia', imgSrc: imageLt, href: '/gallery/latvia', flag: 'lt' },
        { name: 'Russia', imgSrc: imageRu, href: '/gallery/russia', flag: 'ru' },
    ]
</script>

<style>
    .thumbnail-h {
        color: var(--primary);
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .gallery-thumbnail {
        /* border: 1px solid var(--secondary); */
    }
</style>